* {
    box-sizing: border-box;
}
html {
    font-size: calc(14px + 0.390625vw);
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    font-family: 'Epilogue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.rec .rec-dot {
    box-shadow: none;
    width: 11px;
    height: 11px;
    background-color: #d9d9d9;
    margin: 10px;
}
.rec .rec-dot:focus,
.rec .rec-dot:hover {
    box-shadow: none;
    background-color: #d9d9d9;
}
.rec .rec-pagination {
    margin-top: 20px;
}
.rec .rec-dot_active:focus,
.rec .rec-dot_active:hover {
    box-shadow: none;
    background-color: #736f6f;
}

.rec .rec-dot_active {
    box-shadow: none;
    background-color: #736f6f;
}

.open-page-cards .rec .rec-dot {
    box-shadow: none;
    width: 10px;
    height: 10px;
    background-color: rgba(34, 153, 177, 0.5);
    margin: 3px;
}
.open-page-cards .rec .rec-dot:focus,
.rec .rec-dot:hover {
    box-shadow: none;
    background-color: rgba(34, 153, 177, 0.5);
}
.open-page-cards .rec .rec-pagination {
    margin-top: 8px;
}
.open-page-cards .rec .rec-dot_active:focus,
.rec .rec-dot_active:hover {
    box-shadow: none;
    background-color: #2299b1;
}

.open-page-cards .rec .rec-dot_active {
    box-shadow: none;
    background-color: #2299b1;
}

.genAI-service-carouse .rec.rec-arrow {
    background-color: #414141;
    color: black;
    /* width: 75px;
    height: 75px; */
}

.genAI-service-carouse .rec.rec.rec-arrow:active {
    background-color: #f9b67c;
    color: white;
}
.genAI-service-carouse .rec.rec.rec-arrow:hover {
    background-color: #f9b67c;
    color: white;
}
