/* ChatBot Common Style */

.react-chatbot-kit-chat-container {
  width: 450px;
  height: calc(var(--vh, 1vh) * 90);
}

.react-chatbot-kit-chat-inner-container {
  height: 100%;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.09);
  background: rgba(137, 137, 137, 0.05);
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
  background: none;
  padding-left: 32px;
  padding-top: 19.5px !important;
  padding-right: 32px;
  padding-bottom: 18px;
  display: block;
}

.react-chatbot-kit-chat-input-container {
  padding-bottom: 20px;
  padding-top: 20px;
}

.react-chatbot-kit-chat-input-container::before {
  content: "";
  color: rgba(255, 255, 255, 0.51);
  padding-left: 15px;
  position: absolute;
  background-image: url('./NotSend.svg');
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  height: 75%;
  background-color: none;
  z-index: 1;
}

.react-chatbot-kit-chat-message-container {
  /* height: 60% !important; */
  height: calc(100% - 220px);
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
}

.view .react-chatbot-kit-chat-message-container {
  display: block;
}

/* Styling for the chat input */
.react-chatbot-kit-chat-input {
  outline: none;
  background: none;
  border-top: none;
  color: rgba(255, 255, 255, 0.51);
  position: relative;
  display: none;
}


.react-chatbot-kit-chat-input:focus {}

.react-chatbot-kit-chat-input-form {
  height: 64px;
  margin-left: 21px;
  margin-right: 21px;
  border-radius: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), rgba(217, 217, 217, 0.02);
  position: relative;
}

.react-chatbot-kit-chat-input-form::before {
  content: "Kindly Enter the Details to get immersed in our Synth";
  color: rgba(255, 255, 255, 0.51);
  padding-left: 20px;
  position: absolute;
  width: 97%;
  height: 100%;
  background-color: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.react-chatbot-kit-chat-input::placeholder {}

.react-chatbot-kit-chat-btn-send {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), rgba(217, 217, 217, -0.98);
  background-image: url('./SendIcon.svg');
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  display: none;
}

.react-chatbot-kit-chat-btn-send-icon {
  display: none;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  position: relative;
}

.react-chatbot-kit-user-chat-message-container {
  position: relative;

}

.react-chatbot-kit-user-avatar {
  position: absolute;
  bottom: -45px;
}

.react-chatbot-kit-chat-bot-avatar-container {}

.react-chatbot-kit-chat-bot-avatar-icon {}

.react-chatbot-kit-chat-bot-avatar-letter {}

.react-chatbot-kit-chat-bot-message {
  margin-left: 35.5px !important;
}

.react-chatbot-kit-chat-bot-message {
  border-radius: 18px 18px 18px 5px;
  border: 1px solid rgba(112, 112, 112, 0.36);
  background: rgba(217, 217, 217, 0.02);
  color: #FFF;
  font-size: 14px;
  word-break: break-word;
  line-height: 22px;
  font-weight: 400;
  max-width: 75%;
  width: auto;
}

.react-chatbot-kit-user-chat-message {
  color: #FFF;
  font-size: 14px;
  word-break: break-word;
  line-height: 22px;
  font-weight: 400;
  max-width: 75%;
  width: auto;
  border-radius: 18px 18px 5px 18px;
  border: 1px solid rgba(112, 112, 112, 0.36);
  background: rgba(217, 217, 217, 0.02);
  margin-right: 35.5px;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: none;
}

.react-chatbot-kit-chat-bot-loading-icon-container {}

.chatbot-loader-container {}

#chatbot-loader #chatbot-loader-dot1 {}

#chatbot-loader #chatbot-loader-dot2 {}

#chatbot-loader #chatbot-loader-dot3 {}

.react-chatbot-kit-error {}

.react-chatbot-kit-error-container {}

.react-chatbot-kit-error-header {}

.react-chatbot-kit-error-docs {}

.react-chatbot-kit-user-avatar-container {
  background: rgba(230, 230, 230, 0.02);
}

.react-chatbot-kit-user-chat-message-arrow {
  border-left: none;
}

.react-chatbot-kit-chat-bot-message-container img.avatar {
  border: 1px solid #3c4f8d;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  background: #fff;
  position: absolute;
  bottom: -45px;
}

.react-chatbot-kit-chat-header .user {
  border-radius: 18px 18px 18px 5px;
  border: 1px solid rgba(112, 112, 112, 0.36);
  background: rgba(217, 217, 217, 0.02);
  color: #FFF;
  font-size: 14px;
  word-break: break-word;
  line-height: 22px;
  font-weight: 400;
  max-width: 85%;
  width: auto;
  padding: 10px;
  margin-top: 25px;
  margin-left: 10%;
}

.user-form-container {
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 56);
}

.removeForm .react-chatbot-kit-chat-header .user-form-container {
  display: none;
}

.chat-main-window {
  margin: auto;
  right: 20px;
  bottom: 20px;
}

.open .react-chatbot-kit-chat-input {
  display: flex !important;
  background: none;
}

.close .react-chatbot-kit-chat-input-container::before {
  display: none;
}

.closePlaceholder .react-chatbot-kit-chat-input-form::before {
  display: none;
}

.react-chatbot-kit-chat-input::placeholder {
  color: rgba(255, 255, 255, 0.51);
  font-size: 14px;
  font-weight: 400;
}

.openButton .react-chatbot-kit-chat-btn-send {
  display: block;
}

.user-details-form {}

/* ChatBot Common Style */

/* ChatBot Mobile Style */

@media screen and (max-width: 768px) {

  .chat-main-window {
    width: 100% !important;
    top: 0 !important;
    right: 0;
    bottom: 0;
  }

  .react-chatbot-kit-chat-container {
    width: 100% !important;
    height: calc(var(--vh, 1vh) * 97);
  }

  .react-chatbot-kit-chat-inner-container {
    height: 99.9%;
  }

  body.no-scroll {
    overflow: hidden !important;
    background: #000;
  }

  .no-scroll .main-container {
    display: none;
  }

  .react-chatbot-kit-chat-message-container {
    /* height: calc(var(--vh, 1vh) * 65) !important; */
    min-height: calc(100% - 220px);
  }


  .react-chatbot-kit-chat-input-container::before {
    top: 20px;
    padding-top: 14px;
    padding-left: 7%;
    width: 100%;
    height: 60%;
    background-color: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 10px;
    font-weight: 300;
    background-size: 150px;
  }

  .react-chatbot-kit-chat-input-form::before {
    padding-left: 10px;
    align-items: center;
    justify-content: left;
    font-size: 10px;
    font-weight: 400;
  }

  .react-chatbot-kit-chat-header .user {
    border-radius: 18px 18px 18px 5px;
    border: none;
    max-width: 100%;
    margin-left: 0;
    background: transparent;
    padding: 0;
    margin-top: 25px;
  }

}

/* ChatBot Mobile Style */


/* Customize the scrollbar) */
::-webkit-scrollbar {
  width: 0px;
}